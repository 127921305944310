import * as React from 'react';

import { HeadFC, PageProps } from 'gatsby';

import KDContainer from '../components/Container';
import KDRow from '../components/Row';

const NotFoundPage: React.FC<PageProps> = () => {
  return (
    <main>
      <KDContainer>
        <KDRow className="">
          <h1>404</h1>
        </KDRow>
      </KDContainer>
    </main>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
