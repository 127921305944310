import React from 'react';
import { ReactNode } from 'react';
import { Row } from 'react-bootstrap';

type RowProps = {
  children: ReactNode;
  className: string;
};

const rowClasses = 'g-0 ';
export default function KDRow({ children, className }: RowProps) {
  return <Row className={rowClasses + ' ' + className}> {children} </Row>;
}
